import { Link } from "gatsby"
import React from "react"
import { FiSun, FiMoon } from "react-icons/fi"

import SVGLogo from "../components/svgLogo"

/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"

const Header = ({ siteTitle }) => {
  const [colorMode, setColorMode] = useColorMode()

  return (
    <header
      sx={{
        display: [null, "grid"],
        gridGap: 3,
        maxWidth: "container",
        width: "100%",
        mx: "auto",
        px: 3,
        py: 3,
        gridAutoFlow: "row",
        gridTemplateColumns: ["repeat(2, 1fr)", "repeat(3, 1fr)"],
        variant: "styles.header",
      }}
    >
      <div
        sx={{
          display: ["grid", "flex"],
          alignItems: "center",
          justifyContent: "center",
          gridColumnStart: [1, 2],
          gridColumnEnd: [3, 3],
          order: [0, 1],
        }}
      >
        <Link to="/" title="Home">
          <SVGLogo
            width="300px"
            fill={colorMode === "default" ? "#41318E" : "#fff"}
          />
          <span
            sx={{
              position: "absolute",
              width: 1,
              height: 1,
              overflow: "hidden",
              top: -9999,
            }}
          >
            Home
          </span>
        </Link>
      </div>
      <div
        sx={{
          display: ["grid", "flex"],
          alignItems: "center",
          justifyContent: ["center", "flex-start"],
        }}
      >
        <Link
          to="/buecher"
          activeClassName="active"
          sx={{
            variant: "styles.navlink",
            p: 2,
            textDecoration: "unset",
            color: "text",
            "&.active": {
              color: "primary",
            },
            fontSize: 1,
            fontWeight: "black",
            textAlign: ["center", null],
          }}
        >
          Bücher
        </Link>
        <Link
          to="/zeitschriften"
          activeClassName="active"
          sx={{
            variant: "styles.navlink",
            p: 2,
            textDecoration: "unset",
            color: "text",
            "&.active": {
              color: "primary",
            },
            fontSize: 1,
            fontWeight: "black",
            textAlign: ["center", null],
          }}
        >
          Zeitschriften
        </Link>
      </div>
      <div
        sx={{
          display: ["grid", "flex"],
          alignItems: "center",
          justifyContent: ["center", "flex-end"],
          order: 2,
          textDecoration: "unset",
        }}
      >
        <Link
          to="/ueber-uns"
          activeClassName="active"
          sx={{
            variant: "styles.navlink",
            p: 2,
            textDecoration: "unset",
            color: "text",
            "&.active": {
              color: "primary",
            },
            fontSize: 1,
            fontWeight: "black",
            textAlign: ["center", null],
          }}
        >
          Über uns
        </Link>
        <Link
          to="/presse"
          activeClassName="active"
          sx={{
            variant: "styles.navlink",
            p: 2,
            textDecoration: "unset",
            color: "text",
            "&.active": {
              color: "primary",
            },
            fontSize: 1,
            fontWeight: "black",
            textAlign: ["center", null],
          }}
        >
          Presse
        </Link>
        <span
          onClick={e => {
            setColorMode(colorMode === "default" ? "dark" : "default")
          }}
          sx={{
            variant: "styles.navlink",
            p: 2,
            textDecoration: "unset",
            color: "text",
            "&.active": {
              color: "primary",
            },
            fontSize: 1,
            fontWeight: "black",
            cursor: "pointer",
            textAlign: ["center", null],
          }}
        >
          {colorMode === "default" ? (
            <FiSun sx={{ fontSize: 2, mt: 2 }} />
          ) : (
            <FiMoon sx={{ fontSize: 2, mt: 2 }} />
          )}
        </span>
      </div>
    </header>
  )
}

export default Header
