import React from "react"

const svgLogo = ({ fill, width }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="68.000000pt"
      viewBox="0 0 436.000000 68.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g
        transform="translate(0.000000,68.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M3302 673 c-16 -6 -42 -56 -42 -81 0 -11 9 -31 21 -46 28 -35 86 -36
126 -2 21 19 28 33 28 64 0 55 -18 72 -74 71 -25 0 -52 -3 -59 -6z"
        />
        <path
          d="M2000 662 c0 -4 -20 -151 -45 -326 -25 -176 -45 -323 -45 -328 0 -4
31 -8 69 -8 81 0 73 -13 96 150 23 168 23 169 58 191 40 24 85 25 94 2 5 -13
-12 -174 -34 -311 l-6 -33 74 3 74 3 24 178 c29 207 25 238 -29 275 -43 29
-115 27 -179 -6 l-41 -22 5 38 c4 20 12 74 19 120 l13 82 -74 0 c-40 0 -73 -3
-73 -8z"
        />
        <path
          d="M2630 615 c-8 -2 -32 -6 -52 -10 l-36 -6 -7 -64 -7 -65 -34 0 c-37 0
-40 -4 -47 -67 l-5 -43 35 0 c33 0 35 -2 30 -27 -15 -73 -29 -232 -23 -257 4
-15 19 -39 33 -52 23 -21 35 -24 104 -24 103 0 129 12 129 59 0 52 -9 61 -50
52 -76 -16 -80 -5 -58 160 l12 89 63 0 c60 0 63 1 63 24 0 13 3 38 6 55 l7 31
-63 0 -62 0 6 33 c20 115 20 117 -5 116 -13 -1 -31 -3 -39 -4z"
        />
        <path
          d="M1098 466 c-57 -20 -83 -41 -102 -79 -20 -43 -20 -77 -2 -120 17 -42
45 -60 135 -87 68 -20 72 -23 69 -47 -6 -53 -101 -51 -128 3 l-15 30 -58 -14
c-65 -16 -72 -33 -38 -88 29 -47 75 -64 178 -64 112 0 156 22 192 93 45 92 5
163 -112 198 -78 23 -103 39 -94 62 15 40 109 23 115 -21 4 -26 4 -26 71 -10
59 13 65 30 31 86 -38 63 -152 90 -242 58z"
        />
        <path
          d="M1592 465 c-61 -19 -103 -55 -137 -116 -27 -49 -30 -63 -30 -144 0
-77 3 -95 24 -128 34 -55 84 -77 176 -77 56 0 86 5 115 20 46 23 87 74 96 117
6 30 5 31 -37 38 -24 3 -52 9 -61 11 -11 3 -18 0 -18 -9 0 -8 -10 -26 -21 -41
-18 -23 -28 -27 -62 -24 -32 2 -44 9 -55 28 -18 35 -10 140 15 182 40 69 133
57 133 -18 0 -35 1 -36 31 -30 17 3 47 6 67 6 l35 0 -5 51 c-6 62 -31 97 -88
127 -50 25 -112 28 -178 7z"
        />
        <path
          d="M3085 464 c-16 -8 -41 -25 -54 -37 l-23 -22 7 33 7 32 -69 0 -69 0
-28 -207 c-16 -115 -31 -220 -33 -235 l-5 -28 73 0 74 0 20 148 20 147 45 23
c24 12 61 22 82 22 33 0 38 3 38 24 0 13 3 44 6 70 l7 46 -34 0 c-19 0 -47 -7
-64 -16z"
        />
        <path
          d="M3703 456 c-51 -24 -53 -24 -53 -6 0 18 -7 20 -67 20 l-68 0 -28
-208 c-16 -114 -31 -219 -34 -234 l-5 -28 74 0 74 0 22 156 c12 86 27 163 33
170 15 18 83 38 103 31 20 -8 21 -57 2 -180 -8 -50 -17 -111 -20 -134 l-6 -43
73 0 73 0 22 163 c12 89 22 181 22 204 0 99 -106 142 -217 89z"
        />
        <path
          d="M462 308 c-26 -191 -27 -219 -7 -258 21 -40 43 -50 117 -50 48 0 73
6 106 25 l42 24 0 -25 c0 -23 3 -24 60 -24 33 0 60 1 60 3 0 1 14 98 30 216
17 117 30 222 30 232 0 17 -8 19 -69 19 l-70 0 -6 -42 c-4 -24 -14 -97 -23
-163 l-17 -120 -36 -19 c-24 -13 -44 -17 -62 -12 -23 5 -27 12 -27 41 0 30 19
178 36 283 l5 32 -73 0 -74 0 -22 -162z"
        />
        <path
          d="M3256 448 c-3 -13 -17 -108 -31 -213 -14 -104 -28 -200 -30 -212 -5
-22 -3 -23 70 -23 53 0 75 4 75 13 0 6 14 106 30 222 17 115 30 216 30 222 0
9 -21 13 -70 13 -64 0 -70 -2 -74 -22z"
        />
        <path
          d="M45 308 c-33 -18 -45 -40 -45 -83 0 -52 23 -75 73 -75 47 0 86 34 94
82 4 30 1 41 -20 62 -28 28 -68 33 -102 14z"
        />
        <path
          d="M4238 305 c-29 -16 -52 -71 -42 -101 21 -57 87 -73 138 -33 22 17 26
28 26 69 0 39 -4 52 -22 64 -28 20 -65 20 -100 1z"
        />
      </g>
    </svg>
  )
}

export default svgLogo
