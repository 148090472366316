import { Link } from "gatsby"
import React from "react"
import { FaFacebookF } from "react-icons/fa"

/** @jsx jsx */
import { jsx } from "theme-ui"

const Footer = () => (
  <footer
    sx={{
      fontSize: 1,
      color: "background",
      variant: "styles.footer",
    }}
  >
    <div
      sx={{
        display: ["grid", "flex"],
        flexWrap: "wrap",
        alignItems: "center",
        maxWidth: "container",
        mx: [null, "auto"],
        px: 2,
        py: 4,
      }}
    >
      <a
        href="https://www.facebook.com/uschtrin.verlag"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ textAlign: ["center", null] }}
        aria-label="Link zur Uschtrin Verlag Facbeook Seite"
      >
        <FaFacebookF
          sx={{
            fontSize: 4,
            color: "text",
            mr: 2,
          }}
        />
      </a>
      <Link
        to="/bestellformular"
        activeClassName="active"
        sx={{
          p: 2,
          textDecoration: "unset",
          color: "text",
          "&.active": {
            color: "primary",
            fontWeight: 700,
          },
          textAlign: ["center", null],
        }}
      >
        Bestellen
      </Link>
      <Link
        to="/kontakt"
        activeClassName="active"
        sx={{
          p: 2,
          textDecoration: "unset",
          color: "text",
          "&.active": {
            color: "primary",
            fontWeight: 700,
          },
          textAlign: ["center", null],
        }}
      >
        Kontakt
      </Link>
      <Link
        to="/mediadaten"
        activeClassName="active"
        sx={{
          p: 2,
          color: "text",
          textDecoration: "unset",
          "&.active": {
            color: "primary",
            fontWeight: 700,
          },
          textAlign: ["center", null],
        }}
      >
        Mediadaten
      </Link>
      <Link
        to="/impressum"
        activeClassName="active"
        sx={{
          p: 2,
          color: "text",
          textDecoration: "unset",
          "&.active": {
            color: "primary",
            fontWeight: 700,
          },
          textAlign: ["center", null],
        }}
      >
        Impressum
      </Link>
      <Link
        to="/datenschutz"
        activeClassName="active"
        sx={{
          p: 2,
          color: "text",
          textDecoration: "unset",
          "&.active": {
            color: "primary",
            fontWeight: 700,
          },
          textAlign: ["center", null],
        }}
      >
        Datenschutz
      </Link>
      <Link
        to="/widerruf"
        activeClassName="active"
        sx={{
          p: 2,
          color: "text",
          textDecoration: "unset",
          "&.active": {
            color: "primary",
            fontWeight: 700,
          },
          textAlign: ["center", null],
        }}
      >
        Widerruf
      </Link>
      <div sx={{ mx: "auto" }} />
      <div sx={{ p: 2, color: "text", textAlign: ["center", null] }}>
        © {new Date().getFullYear()} Uschtrin Verlag
      </div>
    </div>
  </footer>
)

export default Footer
